.card-categories-info-category {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  .card-categories-info-category-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    h5 {
      margin: 0;
      display: block;
    }

    a {
      display: block;
    }

    span {
      display: block;
      margin-top: 12px;
    }
  }

  .card-categories-info-category-line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

  .card-categories-info-category-content {
    padding: 16px;

    h5 {
      margin: 0;
      margin-bottom: 12px;
    }
  }
}
