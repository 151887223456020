@import './assets/scss/style.scss';
@import './assets/scss/validate.scss';
@import './assets/scss/ant.scss';
@import './assets/scss/mixins';
@import './assets/scss/colors.scss';

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.ant-page-header-back {
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &-button {
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.uploadLabel label{
  white-space: normal;
  max-width: 150px;
}

.modal-filter__button-clear{
  border: none;
}

.d-block{
  display: block;
}

.hide-mobile {
  display: none !important;
}

@media (min-width: 1600px) {
  .hide-desktop {
    display: none !important;
  }
}

.imageUpload{
  width: 100%;
}

@media screen and (max-width: 1600px) {
  .hide-xxl {
    display: none !important;
  }
  .imageUpload{
    flex-flow: row-reverse;
    text-align: right;
  }
}


.page-layout {
  min-height: 100vh;
}

.edit-form {
  .ant-modal-content {
    width: 650px;
  }
  &__save-button {
    width: 100%;
  }
  &__save-photo {
    @include yellow-button;
  }
}

.wordBreakAll{
  word-break: break-all;
}

.ant-message {
  width: auto;
  top: 10px;
  right: 0;
  left: inherit;
}

.ant-input-number-lg,
.ant-input-number-group-wrapper {
  width: 100%;
}
