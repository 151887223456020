.ui-user-card {
  background: #f8f8f8;
  width: 100%;
  padding: 16px;

  &-role {
    text-align: center;
    font-size: 12px;
    color: #818181;
    display: block;
  }

  &-name {
    text-align: center;
    // color: #1890ff;
    font-size: 14px;
    display: block;
  }

  &-avatar {
    display: block !important;
    margin: 10px auto !important;
  }
}
