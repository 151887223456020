.chart-pill {
  &__container {
    margin-top: 16px;
    height: 100%;
  }
  &__table {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__row {
    min-height: 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    &:not(:first-child) {
      margin-bottom: 17px;
    }
    &:first-child {
      margin-bottom: 5px;
    }
  }
  &__info {
    display: flex;
    width: 100%;
    :not(:first-child) span {
      margin-left: -20%;
    }
  }
  &__pill {
    height: 16px;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 70px;
    &--blank {
      background-color: white;
      border: 1px solid #1890ff;
    }
    &--low {
      background-color: #74bcff;
    }
    &--medium {
      background-color: #1890ff;
    }
    &--high {
      background-color: #0659a7;
    }
    &-container {
      width: 18%;
    }
  }
}
