.layout-container__white {
  .white {
    &__wrap {
      padding: 16px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border: 1px solid #d9d9d9;
    }
    &__title {
      &--normal {
        font-size: 16px;
      }
      &--large {
        font-size: 24px;
      }
    }
    &__value {
      font-size: 24px;
    }
  }
}
