.container {
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  max-width: 400px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4c6579;
  z-index: 9999999;
}

@media screen and (max-width: 991px) {
  .container {
    width: calc(100% - 30px);
    right: 15px;
  }
}
