.payment-form {
  background-color: #fff;
  padding: 24px 24px 0;
  &__header {
    text-align: center;
  }
  button{
    height: auto;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      display: flex;
      .st0{
        fill:#24C3D2;
      }
    }
  }
}
