.dayTitle {
  text-align: center;
  position: relative;
  margin-bottom: 10px;

  span {
    background: #F8F8F8;
    display: inline-block;
    padding: 0 18px;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7em;
    line-height: 16px;
    color: #8C8C8C;
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #F8F8F8;
  }
}
