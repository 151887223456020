.avatar {
  a {
    display: flex;
    align-items: center;
  }

  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  & &-name {
    margin-left: 10px;
  }
}
