@import './colors.scss';

#root {
  .ant-typography {
    margin-bottom: 0;
    padding: 0 1px;
  }

  .ant-layout.ant-layout-has-sider>.ant-layout, .ant-layout.ant-layout-has-sider>.ant-layout-content{
    border: 1px solid #f0f0f0;
    margin-top: 1px;
  }
  .ant-menu-vertical{
    border-right: none;
  }

  .ant-layout {
    background: #fff;
  }

  .ant-layout-sider {
    background: #ffffff;
  }

  //Tab
  .ant-tabs-top {
    > .ant-tabs-nav::before {
      border-bottom-color: #dfdfdf;
    }
  }

  //Table
  .ant-table-thead {
    > tr {
      > th {
        font-weight: 900;
      }
    }
  }

  //Header
  .ant-page-header {
    padding: 0;
    margin-bottom: 20px;
  }

  .ant-avatar {
    min-width: 32px;
    max-width: 32px;
  }
  .ant-form-item-explain > div{
    display: none;
    &:first-child{
      display: block;
    }
  }
}

//Colors
a:hover,
.ant-radio-button-wrapper:hover,
.ant-pagination-item:hover a,
.ant-btn-dangerous.ant-btn-link:focus, .ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-link:focus, .ant-btn-link:hover,
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus
.ant-radio-button-wrapper:not([class*=" ant-radio-button-wrapper-disabled"]):hover,
.ant-menu-item, .ant-menu-item a, .ant-menu-item a:hover, .ant-menu-item svg, .ant-menu-item svg:hover,
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item-selected svg, .ant-menu-item-selected svg:hover,
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  transition: all 0.4s;
  color: $black;
  text-shadow: 0 0 1px $blackHover;
}
a,
.ant-btn-dangerous.ant-btn-link, .ant-btn-dangerous.ant-btn-link,
.ant-btn-link,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-item a,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $black;
}
.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: $black;
  fill: $black;
  border-color: $yellowHover;
  background: $yellowHover;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled){
  &:hover {
    border-color: $yellow;
  }
  > span{
    padding: 16px;
  }
}
.ant-btn-dangerous.ant-btn-primary{
  border-color: $black;
  background: $black;
}
.ant-btn-dangerous.ant-btn-primary:focus, .ant-btn-dangerous.ant-btn-primary:hover {
  border-color: $blackHover;
  background: $blackHover;
}
.ant-btn-primary {
  color: $black;
  fill: $black;
  border-color: $yellow;
  background: $yellow;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar,
.ant-tabs-ink-bar {
  background: $black;
}
.ant-switch-checked{
  background-color: $yellow;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before,
.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $yellowOpacity;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before, .ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before, .ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after{
  background-color: $yellow;
}
.ant-layout-sider-trigger{
  background: $yellow;
  color: $black;
}
.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: $black;
}
.ant-radio-inner:after,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
  background-color: $black;
}
.ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link,
// .ant-btn:focus, .ant-btn:hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: $black;
  border-color: $black;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px $yellowShadow;
}
.ant-btn:focus,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before,
.ant-picker:hover,
.ant-picker-input>input:hover,
.ant-radio-checked .ant-radio-inner,
.ant-input-number:hover,
.ant-pagination-item:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-pagination-item-active,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:hover{
  border-color: $black;
}
.ant-picker-focused,
.ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-focused, .ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $black;
  box-shadow: 0 0 0 2px $yellowShadow;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $black;
  background-color: $yellowOpacity;
}