.sort__button {
  fill: #fff;
  svg {
    transform: translate(-3px, 3px);
  }
}
.sort__overlay {
  background-color: #fff;
  padding: 16px;
}
