@import '/src/assets/scss/constant';

.user-profile {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: $card-padding 0;
  &__container {
    padding-left: $card-padding;
    padding-right: $card-padding;
  }
}

@media screen and (max-width: 1366px) {
  #user-profile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;

    .ant-typography strong {
      text-align: center;
    }

    .ant-avatar {
      margin-left: -8px;
    }

    .ant-tag {
      margin-top: 7px;
      margin-left: -13px;
    }
  }

  .user-profile__container {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    h5.ant-typography,
    .ant-typography h5 {
      font-size: 14px;
    }

    .ant-typography {
      font-size: 12px;
    }

    .ant-col {
      font-size: 12px;
      word-wrap: break-word;
    }
  }
}
