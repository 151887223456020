.site-header {
  background: #ffffff !important;
  padding: 0 16px !important;

  .logo,
  .search,
  .user {
    display: flex;
    align-items: center;
  }
  .logo,
  .search {
    justify-content: flex-start;
  }
  .logo {
    display: flex;
    img {
      object-fit: contain;
      object-position: left;
      max-height: 40px;
    }
    &__name {
      font-weight: 700;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .search {
    &-input {
      width: 500px;
    }
  }

  .user {
    justify-content: flex-end;
  }
}
