.product-modal-button {
  margin-top: 16px;
  width: 100%;
}
.promoCode-edit {
  padding: 10px 16px;
  .promoCode-edit__save-button {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    > button {
      max-width: 850px;
      margin: auto;
      display: block;
    }
  }
}
