.product-modal-button {
  margin-top: 16px;
  width: 100%;
}
.order__edit {
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
