.dashboard {
  .ant-page-header-heading-extra {
    display: flex;
    justify-content: flex;
    align-items: center;
    // .ant-row {
    //   &.select {
    //     width: 40%;
    //   }
    // }
    .ant-col {
      &.select {
        margin-left: 50px;
      }
    }
  }
  &__wrap {
    margin-top: 12px;
  }
  .dashboardSelect{
    margin-bottom: 0;
  }
}
