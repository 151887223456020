.user-row {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ant-typography.ant-typography-secondary.user-row__title {
  text-transform: uppercase;
  color: #262626;
  font-weight: 500;
  font-size: 16px;
}

@media screen and (max-width: 1366px) {
  .user-row {
    .ant-typography-secondary {
      padding-right: 10px;
    }
  }
}
