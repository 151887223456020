.header-tab {
  & &__button {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    bottom: 50%;
    transform: translateY(50%);
    span {
      margin: 0;
    }
  }
  &__tab {
    position: relative;
  }
  &__tab:hover &__button {
    opacity: 1;
  }
}
