// FIXME: update fonts

@font-face {
  font-family: 'SF Pro Text';
  src: url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.eot');
  src: url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.svg#SF Pro Text')
      format('svg');
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
input,
pre,
td,
textarea,
th {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  max-height: 100000px;
  font-family: 'SF Pro Text';
}
li {
  list-style-type: none;
}
ul {
  padding-left: 0;
  margin: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
textarea,
input {
  outline: none;
  border: none;
}
button {
  outline: none;
  cursor: pointer;
}

.sort-icon {
  fill: #1890ff;
  margin-right: 9px;
}

.select {
  width: 100%;

  display: flex;
  align-items: center;

  .title {
    width: 15%;
  }

  .select {
    width: 85%;

    & > div {
      width: 100%;
    }
  }
}

.vertical-gap {
  margin: 5px 0;
}
.scroll-y {
  overflow-y: auto;
}

.h200px {
  max-height: 200px;
  height: 100%;
}
.w100 {
  width: 100%;
}
.p-0 {
  padding: 0 !important;
}

.menu__notification {
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  position: relative;
  z-index: 1;
  display: inline-flex;
  color: #000;
  &::after {
    content: '';
    width: 13px;
    height: 13px;
    position: absolute;
    background: #ffa39e;
    display: inline-flex;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.backgroundWrap {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 16px;
}
