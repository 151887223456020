.comments-container {
  .create-form {
    flex-wrap: nowrap;
    margin: 16px 0;
    &__input.ant-form-item {
      flex: auto 1;
      margin: 0;
    }
    &__submit.ant-form-item {
      flex: 0;
    }
  }

  .ant-list-item {
    .ant-btn {
      padding: 0;
    }
  }
}
