@import '../../../assets/scss/mixins';
@import '/src/assets/scss/constant';

.reward-edit {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: $card-padding 0;
  &__container {
    padding-left: $card-padding;
    padding-right: $card-padding;
  }
}

.reward-card {
  &.ant-page-header {
    background-color: transparent;
  }
  &__button-more {
    @include blue-button;
  }
  &__delete-button {
    width: 100%;
  }
}
.reward-edit {
  padding: 10px 16px;
  .reward-edit__save-button {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    > button {
      max-width: 850px;
      margin: auto;
      display: block;
    }
  }
  .close {
    width: 12px;
    margin-left: 0px;
    margin-right: 10px;
    transition: all 0.2s;
    &:hover {
      width: 16px;
      margin-right: 8px;
      margin-left: -2px;
    }
    svg {
      width: 100%;
    }
  }
}
.rewardDetails__header {
  .ant-tabs-nav-wrap {
    margin-top: auto;
  }
  .page-header__buttons > .ant-col-24,
  .page-header__buttons {
    display: flex;
    justify-content: flex-end;
  }
  .ant-tabs-extra-content {
    max-width: 85%;
  }
}
