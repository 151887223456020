.card-products-info-product {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  .card-products-info-product-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    h5 {
      margin: 0;
      display: block;
    }

    a {
      display: block;
    }

    span {
      display: block;
      margin-top: 12px;
    }
  }

  .card-products-info-product-line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

  .card-products-info-product-content {
    padding: 16px;

    h5 {
      margin: 0;
      margin-bottom: 12px;
    }
  }
  .card-products-info-product-effect {
    display: flex;
    flex-wrap: wrap;
    &__item {
      flex: 1;
      min-width: 45%;
      margin: 5px;
      background-color: rgba(#000000, 0.1);
      display: flex;
      padding: 5px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
