.dashboard__progress {
  &--success {
    svg {
      fill: #52c41a;
    }
  }
  &--danger {
    svg {
      fill: #ff4d4f;
    }
  }
  &--warning {
    svg {
      fill: #faad14;
    }
  }
}
