@import '/src/assets/scss/constant';

.divider-container {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: $card-padding 0;
  &__cell {
    padding-left: $card-padding;
    padding-right: $card-padding;
  }
}
