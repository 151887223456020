.chat-newMessage {
  display: flex;
  width: 100%;
  position: relative;
  > .chat-newMessage__input {
    margin-bottom: 0;
    flex-grow: 1;
  }
  > .chat-newMessage__btn {
    height: auto;
  }
}
