.dashboard-pie__legend {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 100%;
  }
  &-color {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
}
