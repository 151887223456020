.product-edit {
  padding: 10px 16px;
  .user-edit__save-button {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    > button {
      max-width: 850px;
      margin: auto;
      display: block;
    }
  }
  .close {
    width: 12px;
    margin-left: 0px;
    margin-right: 10px;
    transition: all 0.2s;
    &:hover {
      width: 16px;
      margin-right: 8px;
      margin-left: -2px;
      // color: #1890ff;
    }
    svg {
      width: 100%;
    }
  }
  // .radioWrap {
  //   max-width: 850px;
  //   margin: auto;
  // }
  .productForm-wrap {
    display: flex;
    > * {
      flex: 1;
    }
  }
}
